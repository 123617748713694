<template>
  <el-dialog
    width="40%"
    :visible="dialogAddProduct"
    @close="handleAddEditProductDialogClose()"
  >
  <span slot="title" class="base-title-font">
      <em
        :style="{color:$store.state.currentTheme.primaryColor}"
        class="iconfont icon-yuandianzhong">
      </em>
      Add Product
    </span>
  <el-form
    :model="productForm"
  >
    <el-form-item
      label="Name"
      :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]"
      style="margin-top: 8px;"
    >
      <el-input
        v-model="productForm.name">
      </el-input>
    </el-form-item>
    <el-form-item 
      label="Oil Type" 
      :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]"
      style="margin-top: 18px;"
    >
      <el-select
        filterable
        style="width: 100%"
        v-model="productForm.oilType"
      >
        <el-option
          v-for="item in OIL_TYPE_OPTIONS"
          :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item 
      label="Fuel Grade Type" 
      :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]"
      style="margin-top: 18px;"
    >
      <el-select
        filterable
        style="width: 100%"
        v-model="productForm.fuelGradeType"
      >
        <el-option
          v-for="item in FUEL_GRADE_TYPE_OPTIONS"
          :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="MPA Code" style="margin-top: 18px;">
      <el-select
        filterable
        style="width: 100%"
        v-model="productForm.mpaCode"
      >
        <el-option
          v-for="code in mpaCodeList"
          :key="code.fuelTypeCode"
          :label="code.fuelTypeCode ? `${code.fuelTypeCode} (${code.fuelTypeDescription})` : 'Select'"
          :value="code.fuelTypeCode">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer">
      <el-button
        round
        @click="handleAddEditProductDialogClose()">
        {{ $t('cancel') }}
      </el-button>
      <el-button
        round
        type="primary"
        @click="handleSubmitClick()">
        {{ $t('submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { OIL_TYPE_OPTIONS, FUEL_GRADE_TYPE_OPTIONS } from '@/constants.js'
export default {
  name: 'DialogAddProduct',
  props: {
    dialogAddProduct: Boolean,
    mpaCodeList: Array
  },
  data () {
    return {
      OIL_TYPE_OPTIONS,
      FUEL_GRADE_TYPE_OPTIONS, 
      productForm: {
        id: null,
        name: null,
        oilType: null,
        fuelGradeType: null,
        mpaCode: null,
      }
    }
  },
  methods: {
    handleAddEditProductDialogClose () {
      this.$emit('handleAddProductDialogClose')
      this.productForm = {
        id: null,
        name: null,
        mpaCode: null
      }
    },
    handleSubmitClick () {
      this.$request.post({
        url: this.$apis.products,
        data: this.productForm
      }).then(res => {
        if (res?.code === 1000) {
          const successMsg = 'Product added successfully'
          this.$emit('handleAddEditProductSuccess', successMsg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-bottom: 0;
}
</style>
