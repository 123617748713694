var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { width: "40%", visible: _vm.dialogAddProduct },
      on: {
        close: function ($event) {
          return _vm.handleAddEditProductDialogClose()
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "base-title-font",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("em", {
            staticClass: "iconfont icon-yuandianzhong",
            style: { color: _vm.$store.state.currentTheme.primaryColor },
          }),
          _vm._v(" Add Product "),
        ]
      ),
      _c(
        "el-form",
        { attrs: { model: _vm.productForm } },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "8px" },
              attrs: {
                label: "Name",
                rules: [
                  {
                    required: true,
                    message: "This field is required",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.productForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.productForm, "name", $$v)
                  },
                  expression: "productForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "18px" },
              attrs: {
                label: "Oil Type",
                rules: [
                  {
                    required: true,
                    message: "This field is required",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "" },
                  model: {
                    value: _vm.productForm.oilType,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "oilType", $$v)
                    },
                    expression: "productForm.oilType",
                  },
                },
                _vm._l(_vm.OIL_TYPE_OPTIONS, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "18px" },
              attrs: {
                label: "Fuel Grade Type",
                rules: [
                  {
                    required: true,
                    message: "This field is required",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "" },
                  model: {
                    value: _vm.productForm.fuelGradeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "fuelGradeType", $$v)
                    },
                    expression: "productForm.fuelGradeType",
                  },
                },
                _vm._l(_vm.FUEL_GRADE_TYPE_OPTIONS, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "18px" },
              attrs: { label: "MPA Code" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "" },
                  model: {
                    value: _vm.productForm.mpaCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "mpaCode", $$v)
                    },
                    expression: "productForm.mpaCode",
                  },
                },
                _vm._l(_vm.mpaCodeList, function (code) {
                  return _c("el-option", {
                    key: code.fuelTypeCode,
                    attrs: {
                      label: code.fuelTypeCode
                        ? `${code.fuelTypeCode} (${code.fuelTypeDescription})`
                        : "Select",
                      value: code.fuelTypeCode,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function ($event) {
                  return _vm.handleAddEditProductDialogClose()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmitClick()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }